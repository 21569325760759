<template>
  <s-dialog to="major-cities-utc-dialog" size="md" open>
    <s-dialog-overlay />
    <s-dialog-panel>
      <st-dialog-header class="!pb-16" @clickClose="onClose">
        <span class="font-bold">{{ $t('studio.timezone_preview_pop_title') }}</span>
      </st-dialog-header>
      <s-dialog-content class="!pb-24">
        <s-dialog-content-body>
          <div class="space-y-24">
            <dl
              v-for="(countryTimezone, index) in listTimeZone"
              :key="index"
              class="flex items-center gap-8"
            >
              <dt class="flex-1 text-2xl font-medium leading-xl text-on-surface-elevation-2">
                {{ countryTimezone.name }}
              </dt>
              <dd class="shrink-0 flex items-center gap-16">
                <span class="text-sm leading-md text-on-surface-elevation-4">{{
                  getDateTimeByLocale(
                    getHourMinutesByTimeZone(countryTimezone.timeZone).date,
                    false
                  )
                }}</span>
                <span class="text-5xl font-bold leading-4xl text-on-surface-elevation-2">
                  {{ getHourMinutesByTimeZone(countryTimezone.timeZone).hourMinutes }}
                </span>
              </dd>
            </dl>
          </div>
        </s-dialog-content-body>
      </s-dialog-content>
    </s-dialog-panel>
  </s-dialog>
  <s-portal-target name="major-cities-utc-dialog" />
</template>
<script setup lang="ts">
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import StDialogHeader from '@/components/common/st-dialog-header.vue';
import { TIME_ZONE, ZONE } from '@/constants/date-time.const';
import { getDateTimeByLocale } from '@/utils/date.util';

const { t } = useI18n();

const props = defineProps<{
  rootDate: Date;
}>();

const emit = defineEmits<{
  close: [inputName: string];
}>();

const onClose = () => {
  emit('close', '');
};

const listTimeZone = [
  { name: t('studio.timezone_preview_pop_seoul'), timeZone: TIME_ZONE.SEOUL },
  { name: t('studio.timezone_preview_pop_tokyo'), timeZone: TIME_ZONE.TOKYO },
  { name: t('studio.timezone_preview_pop_beijing'), timeZone: TIME_ZONE.BEIJING },
  { name: t('studio.timezone_preview_pop_taipei'), timeZone: TIME_ZONE.TAIPEI },
  { name: t('studio.timezone_preview_pop_london'), timeZone: TIME_ZONE.LONDON },
  { name: t('studio.timezone_preview_pop_nyc'), timeZone: TIME_ZONE.NEW_YORK }
];

const getHourMinutesByTimeZone = (timeZone: string): { date: string; hourMinutes: string } => {
  const currentDate = DateTime.fromJSDate(props.rootDate || new Date(), { zone: ZONE.UTC }).setZone(
    timeZone
  );
  return {
    date: currentDate.toFormat('yyyy-MM-dd'),
    hourMinutes: `${currentDate.toFormat('HH')}:${currentDate.toFormat('mm')}`
  };
};
</script>
